import { useAuth0 } from './auth/Auth0Provider';
import { Roles } from './auth/types';

export const useUserRoles = () => {
  const { userIsInRole } = useAuth0();

  const userIsManager = (): boolean => userIsInRole(Roles.administrator) || userIsInRole(Roles.recruiterManager);

  const userHasTalentNetworkAccess = (): boolean =>
    userIsInRole(Roles.administrator) ||
    userIsInRole(Roles.recruiterManager) ||
    userIsInRole(Roles.talentNetworkViewer);

  return { userIsManager, userHasTalentNetworkAccess };
};
