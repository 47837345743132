import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  AdvancedSearchFilters,
  AssignationAdvancedOptions,
  Candidate,
  CandidateByLinkedInProfile,
  CandidateCreate,
  CandidateExternalEdit,
  CandidateListItem,
  CandidateMatchesFilters,
  CandidatesSimilar,
  CandidateTechnology,
  CandidateUpdateLinkedInExtension,
  CustomAccessToken,
  DoNotContactPeriod,
  MatchingCandidate,
  NewAssignation,
  ResumeReview,
  ResumeReviewPayload,
  UnappliedCandidate,
} from '../../components/Candidates/types';
import { KeyValuePair } from '../../components/Common';
import { Assignation, PeriodFilter, TableSort } from '../../components/Common/types';
import { JobApplication, NewJobApplication } from '../../components/JobApplications/types';
import { Parameter } from '../../components/Parameters/types';
import { APIRequestResult, useBaseAPIClient } from './index';

type CandidatesAPIClient = {
  listByStatus: (
    status: number,
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<CandidateListItem>>>;
  listBySimilarProfile: (id: number) => Promise<APIRequestResult<Array<CandidatesSimilar>>>;
  countBySimilarProfile: (id: number) => Promise<APIRequestResult<number>>;
  count: (filter?: string) => Promise<APIRequestResult<number>>;
  countByStatus: (status: number, filter?: string) => Promise<APIRequestResult<number>>;
  get: (id: number) => Promise<APIRequestResult<Candidate>>;
  create: (candidate: CandidateCreate) => Promise<APIRequestResult<Candidate>>;
  createPool: (
    candidate: CandidateCreate,
    tenant: string,
    reCaptchaToken: string,
  ) => Promise<APIRequestResult<Candidate>>;
  getDataFromResume: (file: File | Blob) => Promise<APIRequestResult<Candidate>>;
  contact: (candidateId: number) => Promise<APIRequestResult>;
  approveRequestedAssignation: (
    assignationId: number,
    assignationAdvancedOptions: AssignationAdvancedOptions,
  ) => Promise<APIRequestResult<Assignation>>;
  externalRecruiterCanContact: (
    linkedInProfile?: string,
    email?: string,
    gitHubProfile?: string,
  ) => Promise<APIRequestResult>;
  externalRecruiterCanContactRecaptcha: (
    tenant: string,
    reCaptchaToken: string,
    linkedInProfile?: string,
    email?: string,
    gitHubProfile?: string,
  ) => Promise<APIRequestResult>;
  update: (id: number, candidate: Candidate) => Promise<APIRequestResult<Candidate>>;
  list: (
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<CandidateListItem>>>;
  assign: (id: number, newAssignation: NewAssignation) => Promise<APIRequestResult<Assignation>>;
  remove: (id: number) => Promise<APIRequestResult>;
  getUnappliedCandidates: (
    jobofferId: number,
    filter?: string,
    period?: PeriodFilter,
  ) => Promise<APIRequestResult<Array<UnappliedCandidate>>>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  getStages: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  updateStatus: (
    id: number,
    status: number,
    reasonInput: string,
    stageId?: number,
    hiredFrom?: Dayjs,
    hiredTo?: Dayjs,
    doNotContactPeriod?: DoNotContactPeriod,
  ) => Promise<APIRequestResult<Candidate>>;
  updateStage: (id: number, stage: number) => Promise<APIRequestResult>;
  updateFlags: (id: number, flag: number) => Promise<APIRequestResult>;
  uploadFile: (file: File | Blob) => Promise<APIRequestResult<string>>;
  uploadExternalFile: (file: File | Blob, tenant: string, captchaToken: string) => Promise<APIRequestResult<string>>;
  applyToJobOffer: (
    candidateId: number,
    jobOfferId: number,
    jobApplication: NewJobApplication,
  ) => Promise<APIRequestResult<JobApplication>>;
  unapplyFromJobOffer: (candidateId: number, jobOfferId: number) => Promise<APIRequestResult>;
  unfitForJobOffer: (candidateId: number, jobOfferId: number, reason: string) => Promise<APIRequestResult>;
  asKeyValues: (filter?: string) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  getMatches: (
    jobOfferId: number,
    forceRanker: boolean,
    page: number,
    pageSize: number,
    filters: CandidateMatchesFilters,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<MatchingCandidate>>>;
  countMatches: (
    jobOfferId: number,
    forceRanker: boolean,
    filters: CandidateMatchesFilters,
  ) => Promise<APIRequestResult<number>>;
  acceptPoolCandidate: (
    candidateId: number,
    assignedUserId?: string,
    jobOfferId?: number,
    createTaskForAssignedUser?: boolean,
    taskPriorityLevel?: number,
    taskDescription?: string,
  ) => Promise<APIRequestResult>;
  addImage: (imageURI) => Promise<APIRequestResult>;
  rejectPoolCandidate: (candidateId: number, keepJobApplications?: boolean) => Promise<APIRequestResult>;
  setPicture: (candidateId: number, imageURL: string) => Promise<APIRequestResult>;
  pinCandidate: (candidateId: number) => Promise<APIRequestResult>;
  unpinCandidate: (candidateId: number) => Promise<APIRequestResult>;
  getByLinkedInProfile: (
    linkedInProfile: string,
    type?: string,
  ) => Promise<APIRequestResult<CandidateByLinkedInProfile>>;
  getMostUsedTechs: () => Promise<APIRequestResult<Array<Parameter>>>;
  getCustomAccessTokenForCompleteProfile: (candidateId: number) => Promise<APIRequestResult<CustomAccessToken>>;
  getCandidateForCompleteProfile: (
    customAccessToken: string,
    tenant: string,
  ) => Promise<APIRequestResult<CandidateExternalEdit>>;
  completeCandidateProfile: (
    customAccessToken: string,
    candidate: CandidateExternalEdit,
    tenant: string,
    reCaptchaToken: string,
  ) => Promise<APIRequestResult<CandidateExternalEdit>>;
  search: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<CandidateListItem>>>;
  searchByStatus: (
    status: number,
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<CandidateListItem>>>;
  updatePicture: (candidateId: number, picture: string) => Promise<APIRequestResult<void>>;
  searchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  searchCountByStatus: (
    status: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<number>>;
  createCandidatePrivateProfile: (candidatePrivateProfileId: number) => Promise<APIRequestResult<Candidate>>;
  approveResume: (candidateId: number, resumeId: number, data?: ResumeReviewPayload) => Promise<APIRequestResult<ResumeReview>>;
  rejectResume: (candidateId: number, resumeId: number, data?: ResumeReviewPayload) => Promise<APIRequestResult<ResumeReview>>;
  getResumeReviews: (candidateId: number, resumeId: number) => Promise<APIRequestResult<Array<ResumeReview>>>;
  notInterested: (candidateId: number) => Promise<APIRequestResult>;
  notOpenToChange: (candidateId: number) => Promise<APIRequestResult>;
  onHold: (candidateId: number, notes: string) => Promise<APIRequestResult>;
  validateTechnology: (candidateId: number, technologyId: number) => Promise<APIRequestResult<CandidateTechnology>>;
  unvalidateTechnology: (candidateId: number, technologyId: number) => Promise<APIRequestResult<CandidateTechnology>>;
  updateImportantComments: (candidateId: number, value: { value: string }) => Promise<APIRequestResult<void>>;
  updateTechnologies: (
    candidateId: number,
    technologies: Array<CandidateTechnology>,
  ) => Promise<APIRequestResult<Candidate>>;
  updateCandidateLinkedInExtension: (
    candidateId: number,
    candidateUpdate: CandidateUpdateLinkedInExtension,
  ) => Promise<APIRequestResult<Candidate>>;
};

interface Params {
  origin?: string;
  userCompanyId?: number;
}

export const useCandidatesAPIClient = ({ origin, userCompanyId }: Params = {}): CandidatesAPIClient => {
  const {
    putRequest,
    postRequest,
    getRequest,
    deleteRequest,
    buildPaginatedUrl,
    buildSortUrl,
    uploadFileRequest,
    getFormatedFromDate,
    getFormatedToDate,
    buildPeriodQuery,
  } = useBaseAPIClient({ origin, userCompanyId });

  const { t } = useTranslation();

  const formatAdvancedSearchFilters = (advancedSearchFilters: AdvancedSearchFilters): AdvancedSearchFilters => {
    const formatedAdvancedSearchFilters = { ...advancedSearchFilters };
    formatedAdvancedSearchFilters.createdAtFrom = getFormatedFromDate(advancedSearchFilters.createdAtFrom);
    formatedAdvancedSearchFilters.createdAtTo = getFormatedToDate(advancedSearchFilters.createdAtTo);

    formatedAdvancedSearchFilters.updatedAtFrom = getFormatedFromDate(advancedSearchFilters.updatedAtFrom);
    formatedAdvancedSearchFilters.updatedAtTo = getFormatedToDate(advancedSearchFilters.updatedAtTo);

    return formatedAdvancedSearchFilters;
  };

  const listByStatus = (
    status: number,
    page: number,
    pageSize = 30,
    filter?: string,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<CandidateListItem>>> => {
    let url = buildPaginatedUrl(`candidates/status/${status}`, page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }
    return getRequest(url);
  };

  const get = (id: number): Promise<APIRequestResult<Candidate>> => getRequest<Candidate>(`candidates/${id}`);

  const list = (
    page: number,
    pageSize = 30,
    filter?: string,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<CandidateListItem>>> => {
    let url = buildPaginatedUrl('candidates', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return getRequest(url);
  };

  const search = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<CandidateListItem>>> => {
    return postRequest<Array<CandidateListItem>>(
      buildPaginatedUrl('candidates/search', page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const searchByStatus = (
    status: number,
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<CandidateListItem>>> => {
    return postRequest<Array<CandidateListItem>>(
      buildPaginatedUrl(`candidates/search/status/${status}`, page, pageSize),
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const searchCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> => {
    return postRequest<number>('candidates/search/count', formatAdvancedSearchFilters(advancedSearchFilters));
  };

  const searchCountByStatus = (
    status: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<number>> => {
    return postRequest<number>(
      `candidates/search/status/${status}/count`,
      formatAdvancedSearchFilters(advancedSearchFilters),
    );
  };

  const count = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'candidates/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const countByStatus = (status: number, filter?: string): Promise<APIRequestResult<number>> => {
    let url = `candidates/status/${status}/count`;

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const listBySimilarProfile = (id: number): Promise<APIRequestResult<Array<CandidatesSimilar>>> =>
    getRequest<Array<CandidatesSimilar>>(`candidates/${id}/similar`);

  const countBySimilarProfile = (id: number): Promise<APIRequestResult<number>> =>
    getRequest(`candidates/${id}/similar/count`);

  const asKeyValues = (filter?: string): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    let url = 'candidates/askeyvalues';

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<Array<KeyValuePair>>(url);
  };

    const createCandidatePrivateProfile = (id: number): Promise<APIRequestResult<Candidate>> =>
    postRequest<Candidate>(`candidates/candidate-private-profile/${id}`);


  const approveRequestedAssignation = (
    assignationId: number,
    assignationAdvancedOptions: AssignationAdvancedOptions,
  ): Promise<APIRequestResult<Assignation>> =>
    putRequest(`candidates/assignations/approve/${assignationId}/advanced`, assignationAdvancedOptions);

  const updateStatus = (
    id: number,
    status: number,
    reasonInput: string,
    stageId?: number,
    hiredFrom?: Dayjs,
    hiredTo?: Dayjs,
    doNotContactPeriod?: DoNotContactPeriod,
  ): Promise<APIRequestResult<Candidate>> => {
    const payload = {
      notes: reasonInput,
      stageId,
      hiredFrom: getFormatedFromDate(hiredFrom),
      hiredTo: getFormatedToDate(hiredTo),
      doNotContactPeriod: doNotContactPeriod,
    };

    return putRequest<Candidate>(`candidates/${id}/status/${status}`, payload);
  };

  const updateStage = (id: number, stage: number): Promise<APIRequestResult> =>
    putRequest(`candidates/${id}/stage/${stage}`);

  const updatePicture = (candidateId: number, picture: string): Promise<APIRequestResult> =>
    putRequest(`candidates/${candidateId}/picture`, { picture });

  const updateFlags = (id: number, flag: number): Promise<APIRequestResult> =>
    putRequest(`candidates/${id}/flags/${flag}`);

  const remove = (id: number): Promise<APIRequestResult> => deleteRequest(`candidates/${id}`);

  const assign = (id: number, newAssignation: NewAssignation): Promise<APIRequestResult<Assignation>> =>
    postRequest(`candidates/${id}/assign`, newAssignation);

  const create = (candidate: CandidateCreate): Promise<APIRequestResult<Candidate>> =>
    postRequest<Candidate>('candidates', candidate);

  const contact = (id: number): Promise<APIRequestResult> => {
    return putRequest(`/candidates/${id}/contact`);
  };

  const notInterested = (id: number): Promise<APIRequestResult> => {
    const payload = {
      text: t('contactedFromLinkedin'),
      type: 4,
    };
    return putRequest(`/candidates/${id}/not-interested`, payload);
  };

  const notOpenToChange = (id: number): Promise<APIRequestResult> => {
    const payload = {
      text: t('contactedFromLinkedin'),
      type: 4,
    };
    return putRequest(`/candidates/${id}/not-open-to-change`, payload);
  };

  const onHold = (id: number, notes: string): Promise<APIRequestResult> => {
    const payload = {
      notes,
    };
    return putRequest(`/candidates/${id}/on-hold`, payload);
  };

  const createPool = (
    candidate: CandidateCreate,
    tenant: string,
    reCaptchaToken: string,
  ): Promise<APIRequestResult<Candidate>> => {
    const config = {
      tenant,
      reCaptchaToken,
      ignoreBearerToken: true,
    };

    return postRequest<Candidate>('candidates/pool', candidate, config);
  };

  const externalRecruiterCanContactRecaptcha = (
    tenant: string,
    reCaptchaToken: string,
    linkedInProfile?: string,
    email?: string,
    gitHubProfile?: string,
  ): Promise<APIRequestResult> => {
    const config = {
      tenant,
      reCaptchaToken,
      ignoreBearerToken: true,
    };

    const data = {
      linkedInProfile,
      email,
      gitHubProfile,
    };

    return postRequest(`candidates/external-recruiter/contact/recaptcha`, data, config);
  };

  const externalRecruiterCanContact = (
    linkedInProfile?: string,
    email?: string,
    gitHubProfile?: string,
  ): Promise<APIRequestResult> => {
    const data = {
      linkedInProfile,
      email,
      gitHubProfile,
    };

    return postRequest(`candidates/external-recruiter/contact`, data);
  };

  const update = (id: number, candidate: Candidate): Promise<APIRequestResult<Candidate>> =>
    putRequest<Candidate>(`candidates/${id}`, candidate);

  const getStages = (): Promise<APIRequestResult<Array<KeyValuePair>>> =>
    getRequest<Array<KeyValuePair>>('candidates/stages');

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> =>
    getRequest<Array<KeyValuePair>>('candidates/statuses');

  const getUnappliedCandidates = (
    jobofferId: number,
    filter?: string,
    period?: PeriodFilter,
  ): Promise<APIRequestResult<Array<UnappliedCandidate>>> => {
    let url = `candidates/job-offer/${jobofferId}/unapplied`;

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;

      if (period) {
        url = `${url}&${buildPeriodQuery(period)}`;
      }
    }

    return getRequest<Array<UnappliedCandidate>>(url);
  };

  const applyToJobOffer = (
    candidateId: number,
    jobOfferId: number,
    jobApplication: NewJobApplication,
  ): Promise<APIRequestResult<JobApplication>> =>
    postRequest<JobApplication>(`candidates/${candidateId}/apply/job-offer/${jobOfferId}`, jobApplication);

  const addImage = (imageURI): Promise<APIRequestResult> => postRequest('candidates/file/uri', { imageURI });

  const setPicture = (candidateId: number, imageURL: string): Promise<APIRequestResult> =>
    putRequest(`candidates/${candidateId}/picture`, { picture: imageURL });

  const unapplyFromJobOffer = (candidateId: number, jobOfferId: number): Promise<APIRequestResult> =>
    postRequest(`candidates/${candidateId}/unapply/job-offer/${jobOfferId}`);

  const unfitForJobOffer = (candidateId: number, jobOfferId: number, reason: string): Promise<APIRequestResult> =>
    postRequest(`candidates/${candidateId}/unfit/job-offer/${jobOfferId}`, { reason });

  const uploadFile = (file: File | Blob): Promise<APIRequestResult<string>> =>
    uploadFileRequest(`candidates/file`, file);

  const getDataFromResume = (file: File | Blob): Promise<APIRequestResult<Candidate>> =>
    uploadFileRequest(`candidates/extract`, file);

  const uploadExternalFile = (
    file: File | Blob,
    tenant: string,
    reCaptchaToken: string,
  ): Promise<APIRequestResult<string>> => {
    const config = {
      tenant,
      reCaptchaToken,
      ignoreBearerToken: true,
    };

    return uploadFileRequest(`candidates/pool/file`, file, config);
  };

  const getMatches = (
    jobOfferId: number,
    forceRanker: boolean,
    page: number,
    pageSize: number,
    filters: CandidateMatchesFilters,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<MatchingCandidate>>> => {
    const matchFilters = {
      ...filters,
      finishedInterviewFrom: getFormatedFromDate(filters.finishedInterviewFrom),
      finishedInterviewTo: getFormatedToDate(filters.finishedInterviewTo),
    };

    let url = `/candidates/job-offer/${jobOfferId}/match/unapplied?forceRanker=${forceRanker}`;

    url = buildPaginatedUrl(url, page, pageSize);

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return postRequest<Array<MatchingCandidate>>(url, matchFilters);
  };

  const countMatches = (
    jobOfferId: number,
    forceRanker: boolean,
    filters: CandidateMatchesFilters,
  ): Promise<APIRequestResult<number>> => {
    const matchFilters = {
      ...filters,
      finishedInterviewFrom: getFormatedFromDate(filters.finishedInterviewFrom),
      finishedInterviewTo: getFormatedToDate(filters.finishedInterviewTo),
    };

    return postRequest<number>(
      `/candidates/job-offer/${jobOfferId}/match/unapplied/count?forceRanker=${forceRanker}`,
      matchFilters,
    );
  };

  const acceptPoolCandidate = (
    candidateId: number,
    assignedUserId?: string,
    jobOfferId?: number,
    createTaskForAssignedUser?: boolean,
    taskPriorityLevel?: number,
    taskDescription?: string,
  ): Promise<APIRequestResult> => {
    const payload = {
      assignedUserId,
      jobOfferId,
      createTaskForAssignedUser,
      taskPriorityLevel,
      taskDescription,
    };
    return postRequest(`candidates/pool/${candidateId}/accept`, payload);
  };

  const rejectPoolCandidate = (candidateId: number, keepJobApplications?: boolean): Promise<APIRequestResult> => {
    const queryParam = keepJobApplications !== undefined ? `?keepJobApplications=${keepJobApplications}` : '';
    return postRequest(`candidates/pool/${candidateId}/reject${queryParam}`);
  };

  const pinCandidate = (candidateId: number): Promise<APIRequestResult> => postRequest(`candidates/${candidateId}/pin`);

  const unpinCandidate = (candidateId: number): Promise<APIRequestResult> =>
    postRequest(`candidates/${candidateId}/unpin`);

  const getByLinkedInProfile = (
    linkedInProfile: string,
    type?: string,
  ): Promise<APIRequestResult<CandidateByLinkedInProfile>> =>
    getRequest<CandidateByLinkedInProfile>(
      `candidates/linkedInProfile/${linkedInProfile}${type ? `?type=${type}` : ''}`,
    );

  const getMostUsedTechs = (): Promise<APIRequestResult<Array<Parameter>>> =>
    getRequest<Array<Parameter>>('/candidates/toptechs');

  const getCustomAccessTokenForCompleteProfile = (candidateId: number): Promise<APIRequestResult<CustomAccessToken>> =>
    postRequest(`candidates/${candidateId}/complete-profile/accessToken`, undefined);

  const getCandidateForCompleteProfile = (
    customAccessToken: string,
    tenant: string,
  ): Promise<APIRequestResult<CandidateExternalEdit>> => {
    const config = {
      headers: [{ name: 'Tenant', value: tenant }],
      ignoreBearerToken: true,
    };

    return getRequest<CandidateExternalEdit>(`candidates/complete-profile/${customAccessToken}`, config);
  };

  const completeCandidateProfile = (
    customAccessToken: string,
    candidate: CandidateExternalEdit,
    tenant: string,
    reCaptchaToken: string,
  ): Promise<APIRequestResult<CandidateExternalEdit>> => {
    const config = {
      headers: [
        { name: 'Tenant', value: tenant },
        { name: 'ReCaptchaToken', value: reCaptchaToken },
      ],
      ignoreBearerToken: true,
    };

    return putRequest(`candidates/complete-profile/${customAccessToken}`, candidate, config);
  };

  const approveResume = (
    candidateId: number,
    resumeId: number,
    data?: ResumeReviewPayload,
  ): Promise<APIRequestResult<ResumeReview>> => {
    return putRequest(`candidates/${candidateId}/resumes/${resumeId}/review/approved`, data);
  };

  const rejectResume = (
    candidateId: number,
    resumeId: number,
    data?: ResumeReviewPayload,
  ): Promise<APIRequestResult<ResumeReview>> => {
    return putRequest(`candidates/${candidateId}/resumes/${resumeId}/review/rejected`, data);
  };

  const getResumeReviews = (candidateId: number, resumeId: number): Promise<APIRequestResult<Array<ResumeReview>>> => {
    return getRequest(`candidates/${candidateId}/resumes/${resumeId}/reviews`);
  };

  const validateTechnology = (
    candidateId: number,
    technologyId: number,
  ): Promise<APIRequestResult<CandidateTechnology>> => {
    return putRequest(`candidates/${candidateId}/technology/${technologyId}/validate`);
  };

  const unvalidateTechnology = (
    candidateId: number,
    technologyId: number,
  ): Promise<APIRequestResult<CandidateTechnology>> => {
    return putRequest(`candidates/${candidateId}/technology/${technologyId}/unvalidate`);
  };

  const updateImportantComments = (candidateId: number, value: { value: string }): Promise<APIRequestResult<void>> => {
    return putRequest(`candidates/${candidateId}/important-comments`, value);
  };

  const updateTechnologies = (
    candidateId: number,
    technologies: Array<CandidateTechnology>,
  ): Promise<APIRequestResult<Candidate>> => {
    const payload = {
      id: candidateId,
      technologies,
    };
    return putRequest(`candidates/${candidateId}/technologies`, payload);
  };

  const updateCandidateLinkedInExtension = (
    candidateId: number,
    candidateUpdate: CandidateUpdateLinkedInExtension,
  ): Promise<APIRequestResult<Candidate>> => {
    const payload = {
      id: candidateId,
      ...candidateUpdate,
    };
    return putRequest(`candidates/${candidateId}/linked-in-extension`, payload);
  };

  return {
    list,
    listByStatus,
    listBySimilarProfile,
    countBySimilarProfile,
    countByStatus,
    count,
    get,
    approveRequestedAssignation,
    updateStatus,
    updateStage,
    updateFlags,
    updatePicture,
    remove,
    assign,
    create,
    getDataFromResume,
    contact,
    createPool,
    externalRecruiterCanContact,
    externalRecruiterCanContactRecaptcha,
    update,
    asKeyValues,
    getUnappliedCandidates,
    getStages,
    getStatuses,
    applyToJobOffer,
    setPicture,
    unapplyFromJobOffer,
    unfitForJobOffer,
    uploadFile,
    addImage,
    uploadExternalFile,
    getMatches,
    countMatches,
    acceptPoolCandidate,
    rejectPoolCandidate,
    pinCandidate,
    unpinCandidate,
    getByLinkedInProfile,
    getMostUsedTechs,
    getCustomAccessTokenForCompleteProfile,
    getCandidateForCompleteProfile,
    completeCandidateProfile,
    createCandidatePrivateProfile,
    search,
    searchByStatus,
    searchCount,
    searchCountByStatus,
    approveResume,
    rejectResume,
    getResumeReviews,
    notInterested,
    notOpenToChange,
    onHold,
    validateTechnology,
    unvalidateTechnology,
    updateImportantComments,
    updateTechnologies,
    updateCandidateLinkedInExtension,
  };
};
